@font-face {
    font-family: 'Arial';
    src:url("../fonts/Arial.ttf");
}

// ITC Avant Garde Gothic Pro Bold
$title-font: turbinado-pro, sans-serif;
$title-light: 300;

$body-font: 'Open Sans', Arial, sans-serif;

$color-blue: #00B1B1;
$color-grey: #50687B;

$font-primary: $title-font;

$color-primary: #661c4a;
$color-secondary: #978a37;
$color-white: #fff;
$color-black: #000;

h1, h2, h3, h4, h5, p, a, span, li {
	font-family: $body-font;
}

:root {
	--font-small: 15px;

	@media(min-width:768px){
		--font-small:16px;
	}
	@media(min-width:992px){
		--font-small:17px;
	}
}

strong {
	font-family: $title-font;
	font-weight: $title-light;
}

body.__locked {
	overflow: hidden;
}


.homeinstead {
	width: 100%;
	height: auto;
	position: relative;
	overflow: hidden;
}

.homeinstead-header {
	padding: 1.5rem 0;
	background-color: $color-white;

	@media(min-width: 992px){
		padding: 2.5rem 0;
	}
}

.homeinstead-header {
	width: 100%;
	height: auto;
	position: relative;
}

.homeinstead-logo {
	width: 300px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;

	@media(min-width: 768px){
		width: 445px;
	}
}

.homeinstead-intro {
	background-color: $color-primary;
	padding: 1.5rem 0;
	color: $color-white;
	text-align: center;

	h3 {
		font-size: 23px;
		width: 90%;
		margin: 0 auto;
		@media(min-width: 768px){
			font-size: 36px;
			width: 600px;
		}
		@media(min-width: 992px){
			font-size: 40px;
			line-height: 55px;
			width: 690px;
		}

		span {
			width: 67px;
			height: 17px;
			position: relative;
			display: inline-block;
			@media(min-width: 768px){
				width: 83px;
				height: 25px;
			}
			@media(min-width: 992px){
				width: 104px;
				height: 27px;
			}

		}

		strong {
			position: absolute;
			transform: translate(-50%, -50%);
			top: 50%;
			left: 50%;
			color: $color-white;
			font-size: 60px;
			@media(min-width: 768px){
				font-size: 80px;
			}
			@media(min-width: 992px){
				font-size: 100px;
			}
		}
	}
}

.homeinstead-banner {
	width: 100%;
	height: 325px;
	position: relative;

	@media(min-width: 768px){
		height: 450px;
	}
	@media(min-width: 992px){
		height: 525px;
	}

	.videoplay {

		@media(min-width: 768px){
			width: 90px;
			height: 90px;
		}

		@media(min-width: 992px){
			width: 110px;
			height: 110px;
		}

		svg {
			width: 100%;
			height: 100%;
		}
	}
}

.homeinstead-banner__text {
	position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
	color: $color-white;
	font-family: $title-font;
	font-weight: $title-light;
	z-index: 4;
	font-size: 38px;
	transition: all 0.3s ease;

	@media(min-width: 768px){
		font-size: 60px;
		bottom: 13%;
	}
	@media(min-width: 992px){
		font-size: 72px;
		bottom: 12%;
	}
}

.showreel-video {
	width: 100%;
	height: 100%;
    position: absolute;
    top: 0;
    left: 0;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $color-black;
		opacity: 0;
		z-index: 2;
		transition: all 0.3s ease;
	}

	&:hover,
	&:focus {
		// svg {
		// 	fill: $color-secondary;
		// }
		&::before {
			opacity: 0.3;
		}
	}
}


img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.videoplay {
	position: absolute;
	z-index: 3;
	top: 50%;
	left: 50%;
	width: 40px;
	height: 40px;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease;

	@media(min-width: 375px){
		width: 55px;
		height: 55px;
	}

	@media(min-width: 768px){
		width: 40px;
		height: 40px;
	}

	@media(min-width: 992px){
		width: 55px;
		height: 55px;
	}

	svg {
		fill: $color-white;
		width: 100%;
		height: 100%;
		transition: all 0.3s ease;
	}

}

.homeinstead-info {
	width: 90%;
	margin: 0 auto;
	height: auto;
	position: relative;
}

.homeinstead-info__videos {
	width: 100%;
	padding-top: 2rem;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 120px 120px;
	grid-gap: 1rem;
	justify-content: center;
	padding-bottom: calc(150px + 1rem);
	position: relative;

	@media(min-width: 375px){
		grid-template-columns: 150px 150px;
		margin: 0 auto 2rem auto;
	}

	@media(min-width: 768px){
		display: grid;
		grid-template-columns: 120px 120px 120px 120px 120px;
		grid-gap: 2rem;
		margin-bottom: 3.5rem;
		padding-top: 3.5rem;
		padding-bottom: 0;
	}
	@media(min-width: 992px){
		grid-template-columns: 170px 170px 170px 170px 170px;
	}
	@media(min-width: 1300px){
		grid-gap: 3rem;
		grid-template-columns: 200px 200px 200px 200px 200px;
	}
}

.homeinstead-info__item {
	width: 100%;
	height: 120px;
	position: relative;
	display: block;

	@media(min-width: 375px){
		height: 150px;
	}

	@media(min-width: 768px){
		height: 120px;
	}

	@media(min-width: 992px){
		height: 170px;
	}

	@media(min-width: 1300px){
		height: 200px;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: relative;
		z-index: 1;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $color-black;
		opacity: 0;
		z-index: 2;
		transition: all 0.3s ease;
	}

	&:hover,
	&:focus {
		// svg {
		// 	fill: $color-secondary;
		// }
		&::before {
			opacity: 0.6;
		}
		// .homeinstead-info__name {
		// 	color: $color-secondary;
		// }
	}

	&:last-of-type {
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 120px;
		transform: translate(-50%, -25%);

		@media(min-width: 375px){
			width: 150px;
			transform: translate(-50%, 0%);
		}

		@media(min-width: 768px){
			position: relative;
			bottom: auto;
			left: auto;
			transform: none;
			width: 100%;
		}

	}
}

.homeinstead-info__name {
	position: absolute;
	bottom: -4px;
	left: 50%;
	transform: translate(-50%, 0);
	color: $color-white;
	font-family: $title-font;
	font-weight: $title-light;
	z-index: 4;
	font-size: 32px;
	transition: all 0.3s ease;

	@media(min-width: 768px){
		font-size: 32px;
		bottom: -4px;
	}
	@media(min-width: 992px){
		font-size: 49px;
	}
}

.homeinstead-info__footer {
	width: 100%;
	height: auto;
	position: relative;
	text-align: center;
	margin-bottom: 1.5rem;
	display: block;
	@media(min-width: 768px){
		width: 700px;
    	margin: 0 auto 2.5rem auto;
	}

	h4 {
		color: $color-primary;
		font-size: 22px;
		line-height: 32px;
		@media(min-width: 768px){
			font-size: 28px;
			line-height: 37px;
		}
		@media(min-width: 992px){
			font-size: 32px;
			line-height: 40px;
		}

		span {
			width: 46px;
			height: 14px;
			position: relative;
			display: inline-block;
			@media(min-width: 768px){
				width: 74px;
				height: 21px;
			}
			@media(min-width: 992px){
				width: 74px;
				height: 21px;
			}

		}

		strong {
			position: absolute;
			transform: translate(-50%, -50%);
			top: 50%;
			left: 50%;
			color: #ba3065;
			font-size: 45px;
			@media(min-width: 768px){
				font-size: 58px;
			}
			@media(min-width: 992px){
				font-size: 70px;
			}
		}
	}
}

.homeinstead-footer {
	background-color: $color-secondary;
	padding: 2rem 0;
	text-align: center;

	h4 {
		color: $color-white;
		font-size: 13px;
		line-height: 18px;

		@media(min-width: 425px){
			font-size: 15px;
			line-height: 21px;
		}
		@media(min-width: 768px){
			font-size: 28px;
			line-height: 37px;
		}
		@media(min-width: 992px){
			font-size: 32px;
			line-height: 45px;
			width: 770px;
			margin: 0 auto;
		}
	}

	a {
		color: $color-white;
		text-decoration: none;
		transition: all 0.3s ease;

		&:hover,
		&:focus {
			color: rgb(205, 205, 205);
		}
	}
}

.mfp-bg {
	opacity: 0.9!important;
	background: #000!important;
}
.mfp-close {
	color: $color-primary!important;
	font-size: 50px!important;
	height: 44px!important;
	opacity: 1!important;
	transition: all 0.3s ease!important;
	top: -40px!important;

	@media(min-width: 1600px){
		font-size: 75px!important;
	}

	&:hover,
	&:focus {
		color: #ba3065!important;
	}
}

.mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 90%!important;
	position: relative;

	@media(min-width: 768px){
		max-width: 70%!important;
	}
	@media(min-width: 992px){

	}

	@media(min-width: 1600px){
		max-width: 90%!important;
		height: 75vh!important;
		#banner-video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			video {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			.mfp-close {
				top: -47px!important;
			}
		}
		.local-video-popup.small {
			width: 900px;
			height: 900px;
		}
	}


}

.mfp-wrap {
	width: 100%;
	height: 100%;
}

.local-video-popup {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	video {
		width: 100%;
		object-fit: contain;
	}

	&.small {

		@media(min-width: 768px){
			width: 70%;
			margin: 0 auto;
		}

		@media(min-width: 992px){
			width: 500px;
			height: 500px;
			margin: 0 auto;
		}
		video {
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}
}